import LazyLoad from "vanilla-lazyload";

class AvLazyloadImages {
    constructor() {
        this.init();
    }

    init() {
        const obj = new LazyLoad({
            elements_selector: ".av-lazyload-image",
        });

        console.log(obj)
    }
}

export default AvLazyloadImages;
